/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

$(function () {
    update_breadcrumb();

    $(window).resize(function() {
        update_breadcrumb();
    });
});